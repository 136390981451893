export const _CATCH_PREFIX = "_sfxh";

export const _APP_TOKEN_ = `${_CATCH_PREFIX}_catch_user_token`;

// 604800000  7天 - 1分钟
export const EXPIRE_TIMER = 604800000 - 60 * 1000;
const KEY = "_app_expire_timer_";

export const setExpire = (t) => {
  let et = t || EXPIRE_TIMER;
  let timer = Date.now() + et;
  console.log(KEY, timer);
  window.localStorage.setItem(KEY, timer);
};
export const getExpire = () => {
  return window.localStorage.getItem(KEY);
};
export const celarExpire = () => {
  window.localStorage.removeItem(KEY);
};
export const expireChecked = () => {
  let t = getExpire();
  if (!t) {
    localStorage.removeItem(KEY);
    return false; // 过期
  }
  const timer = Number(t);
  const now = Date.now();
  if (now >= timer) {
    localStorage.removeItem(KEY);
    return false; // 过期
  }
  return true; // 未过期
};

export const setToken = (token) => {
  try {
    localStorage.setItem(_APP_TOKEN_, token);
  } catch (error) {
    console.error(error);
  }
};

export const getToken = () => {
  try {
    return localStorage.getItem(_APP_TOKEN_);
  } catch (error) {
    console.error(error);
  }
};

export const removeToken = () => {
  try {
    localStorage.removeItem(_APP_TOKEN_);
  } catch (error) {
    console.error(error);
  }
};

export const clearCache = () => {
  try {
    removeToken();

    // 需要删除的缓存前缀
    const _regx = new RegExp(`(${_CATCH_PREFIX})`);

    // 遍历删除缓存
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (_regx.test(key)) {
        localStorage.removeItem(key);
      }
    }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const tologin = () => {
  clearCache();
  // 转到登录页
  setTimeout(() => {
    let isLoginPage = window.location.href.includes("/login");
    if (!isLoginPage) {
      window.location.href = `/login?return=${encodeURIComponent(
        window.location.href
      )}`;
    }
  }, 1500);
};
